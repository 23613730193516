<template>
    <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Printing - P Plate piano certificate
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Printing
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                    <span>
                        <a href="#" @click="viewLoadingPrint" class="font-weight-bold">
                       Print queue</a
                        >
                    </span>
                  <span v-if="currentUser.access_type == 'score'">
                   | <a href="#" v-copy="appUrl+'bulk-printing?score_prefix='+score_purchase_prefix+'&user='+this.currentUserEncryptedId+'&private=true'" class="font-weight-bold"> <i class="fas fa-copy"></i> Copy Print URL</a>
                  </span>
                  <span v-if="currentUser.access_type == 'federal'">
                    | <a href="#" v-copy="appUrl+'bulk-printing?user='+this.currentUserEncryptedId+'&private=true'" class="font-weight-bold">Copy Print URL</a>
                  </span>
                  | <span><a href="https://s3.ap-southeast-2.amazonaws.com/signatures.ameb.edu.au/score_softwares/SCORE_Bulk_Printing.exe" class="font-weight-bold" target="_blank">   Download print software</a></span>
                 </div>
              </div>
            </div>
          </div>

            <div class="card-body">

            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">

                    <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 3">
                        <v-select 
                            label="What do you want to print?"
                            v-model="print_type" 
                            :items="print_types"
                            item-text="name"
                            item-value="value"
                            outlined 
                            disabled
                            dense
                            clearable
                        ></v-select>
                    </v-col>
                    <v-col cols="12" :md="currentUser.access_type != 'score' && (exam_type!='Practical') ? 2 : 2" v-if="print_type!=''">
                      <v-select  v-model="search.enrolment_year_id" outlined label="Year"
                                 item-text="year" :items="enrolment_years"
                                 item-value="year"
                                 dense
                                 clearable>
                        <template v-slot:no-data>
                          <div class="no-data-auto-complete text-center">No data available </div>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 3">
                        <v-select 
                            label="Online or practical exams"
                            v-model="exam_type" 
                            :loading="isSelectExamTypeLoading"
                            :items="exam_types"
                            item-text="name"
                            item-value="value"
                            outlined 
                            disabled
                            dense
                            @change="selectExamType"
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-select>
                    </v-col>
                    
                    <v-col v-if="currentUser.access_type == 'federal'" class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 3">
                        <v-select 
                            label="SCORE" 
                            v-model="search.score_id" 
                            :items="scores"
                            item-text="name"
                            item-value="id"
                            @change="handleScoreChange"
                            outlined 
                            dense
                            @input="search.score_id = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-select>
                    </v-col>

                    <v-col v-if="exam_type" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-text-field 
                            label="Enrolment key"
                            v-model="search.exam_key" 
                            outlined 
                            dense
                            @input="search.exam_key = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-text-field>
                    </v-col>
                    
                    <v-col v-if="exam_type" class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-text-field 
                            label="First name"
                            v-model="search.first_name" 
                            outlined 
                            dense
                            @input="search.first_name = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-text-field>
                    </v-col>

                    <v-col v-if="exam_type" class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-text-field 
                            label="Surname"
                            v-model="search.last_name" 
                            outlined 
                            dense
                            @input="search.last_name = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-text-field>
                    </v-col>

                    <v-col v-if="exam_type == 'practical_exam' && (print_type=='label' || print_type=='report' || print_type=='certificate')" class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-select 
                            label="Select location"
                            v-model="search.location_id" 
                            :items="locations"
                            item-text="name"
                            item-value="id"
                            outlined 
                            @change="handleLocationChange"
                            dense
                            :loading="isLocationLoading"
                            @input="search.location_id = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-select>
                    </v-col>

                    <v-col v-if="exam_type == 'practical_exam' && (print_type=='label' || print_type=='report' || print_type=='certificate')" class="d-flex" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-select 
                            label="Grade" 
                            v-model="search.grade_id" 
                            outlined 
                            dense
                            :items="grades"
                            item-text="name"
                            item-value="id"
                            @input="search.grade_id = $event !== null ? $event : ''" 
                            clearable
                            v-on:keyup.enter="searchPendingData"
                        ></v-select>
                    </v-col>

                    <v-col class="d-flex" v-if="exam_type" cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                        <v-select 
                            label="Notification type"
                            v-model="search.notification_type" 
                            outlined 
                            :items="notification_types"
                            item-text="name"
                            item-value="value"
                            dense
                            @input="search.notification_type = $event !== null ? $event : ''" 
                            clearable
                        ></v-select>
                    </v-col>

                    <v-col class="d-flex" v-if="exam_type == 'practical_exam' " cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                    <v-autocomplete 
                        label="Exam session" 
                        v-model="search.exam_session_id" 
                        outlined 
                        dense
                        :items="examSessions"
                        item-text="name"
                        item-value="id"
                        :loading="isExamSessionLoading"
                        @input="search.exam_session_id = $event !== null ? $event : ''" 
                        @change="handleExamSessionChange"
                        clearable
                    ></v-autocomplete>
                    </v-col>

                    <v-col class="d-flex" v-if="exam_type == 'practical_exam' " cols="12" sm="6" :md="currentUser.access_type != 'score' ? 2 : 2">
                    <v-autocomplete 
                        label="Exam day" 
                        v-model="search.exam_day_id" 
                        outlined 
                        dense
                        :items="examDays"
                        item-text="name"
                        item-value="id"
                        :loading="isExamDayLoading"
                        @input="search.exam_day_id = $event !== null ? $event : ''" 
                        clearable
                    ></v-autocomplete>
                    </v-col>

                  <v-col class="d-flex"  cols="12" sm="6" md="3" v-if="exam_type && (print_type=='label' || print_type=='report' || print_type=='certificate')" :md="currentUser.access_type != 'score' ? 2 : 2">
                    <v-select
                        label="Printing status"
                        v-model="search.printing_status"
                        outlined
                        :items="printing_statuses"
                        item-text="name"
                        item-value="value"
                        dense
                        @input="search.printing_status = $event !== null ? $event : ''"
                    ></v-select>
                  </v-col>
                    
                    <v-col cols="12" md="12" class="text-right">
                    <v-btn 
                        v-if="exam_type && print_type"
                        @click="searchPendingData()" 
                        class="btn btn-primary btn-search"
                        style="color: #fff"
                        :loading="loading"
                    >
                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                        Search
                    </v-btn>
                    </v-col>
                </div>
            </div>
            
            <div class="row">
              <div class="col-md-4">
                <div class="mt-6" v-if="print_type=='label' || print_type=='report' || print_type=='certificate'">
                  <span><b>Total pending: </b><span class="badge badge-warning">{{total}}</span></span>
                  <span class="ml-3"><b>Total selected: </b><span class="badge badge-warning">{{candidate_ids.length}}</span></span>
                </div>
              </div>
              <div class="col-md-8">
                <div v-if="pendingData.length > 0" class="text-right mt-6">
                  <v-btn
                      v-if="print_type == 'certificate'"
                      x-large
                      text
                      :loading="loadingExportCsv"
                      @click="exportCsv()"
                  >
                    Export CSV
                  </v-btn>
                  <v-btn
                      text
                      x-large
                      class="ml-2"
                      :loading="isAllDataLoading"
                      @click="openNotificationTitleDialog('all')"
                  >
                    Prepare all
                  </v-btn>
                  <v-btn
                      class="btn btn-primary ml-2 text-white"
                      medium
                      :loading="isSelectedDataLoading"
                      @click="openNotificationTitleDialog('selected')"
                  >
                    Prepare selected
                  </v-btn>
                  <v-btn
                      color="blue"
                      class="text-white ml-3"
                      medium
                      v-if="print_type=='certificate'"
                      :loading="isMarkAsDispatchedLoading"
                      @click="markPrintedDispatched('dispatched')"
                      :disabled="candidate_ids.length == 0">
                    Mark as sent
                  </v-btn>
                  <v-btn
                      color="success"
                      class="text-white ml-3"
                      medium
                      v-if="print_type=='certificate'"
                      :loading="isMarkAsCompleteLoading"
                      @click="markPrintedDispatched('printed')"
                      :disabled="candidate_ids.length == 0"
                  >
                    Mark as printed
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="table-responsive mt-2">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>

                <table class="table" v-if="!loading && pendingData.length > 0">
                    <thead>
                        <tr class="text-left">
                        <th class="px-3">
                            <v-checkbox
                                @click="checkAllCandidate"
                                v-model="checkAllCandidateFlag"
                            ></v-checkbox>
                        </th>
                        <th class="px-3" style="max-width: 90px !important; white-space: pre-wrap;">Enrolment key </th>
                        <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">First name </th>
                        <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">Middle name </th>
                        <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;">Surname </th>
                        <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;" v-if="exam_type == 'practical_exam' && print_type=='report'">Enroller </th>
                        <th class="px-3" style="max-width: 120px !important; white-space: pre-wrap;" v-if="exam_type == 'practical_exam' && print_type=='label'">Certificate print date </th>
                        <th class="px-3" style="max-width: 220px !important; white-space: pre-wrap;" >Exam name </th>
                        <th class="px-3" style="max-width: 150px !important; white-space: pre-wrap;">Notification preference </th>
                        <th class="px-3">Status </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template>
                            <tr v-for="(item, index) in pendingData" :key="index">
                                <td class="px-2">
                                    <v-checkbox
                                        v-model="candidate_ids"
                                        :value="item.id"
                                    ></v-checkbox>
                                </td>

                                <td class="px-2">
                                    <a @click="getCandidateSummary(item.exam_key)" href="javascript:;" class="font-weight-bolder text-dark-75 text-hover-primary mb-1 font-size-lg">
                                    {{ item.exam_key }}
                                    </a>
                                    <div v-if="item.has_corequisites_requirement" class="ml-3 mt-2">
                                        <a title="Additional requirements apply to this exam type" href="javascript:;" @click="getCoRequisiteProduct(item.product_id)">
                                            <i class="fa fa-info"></i>
                                        </a>
                                    </div>
                                </td>
                                <td style="max-width: 120px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.first_name}}</span>
                                </td>
                                <td style="max-width: 120px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.middle_name}}</span>
                                </td>
                                <td style="max-width: 120px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.last_name}}</span>
                                </td>
                                <td style="max-width: 120px !important; white-space: pre-wrap;" v-if="exam_type == 'practical_exam' && print_type=='report'">
                                    <span class="font-size-lg">{{item.accountholder_full_name}}</span>
                                </td>
                                
                                <td v-if="exam_type == 'practical_exam' && print_type=='label'">
                                    <span class="font-size-lg">{{item.certificate_printed_date}}</span>
                                </td>
                                <td style="max-width: 220px !important; white-space: pre-wrap;">
                                    <p class="font-size-lg">{{item.exam_name}}</p>
                                    <div class="font-size-lg mt-1" v-if="exam_type=='practical_exam'"> <strong>Location</strong>: {{item.location_name}}</div>
                                    <div class="font-size-lg mt-1" v-if="exam_type=='practical_exam'"> <strong>Exam session</strong>: {{item.exam_session_name}}</div>
                                    <div class="font-size-lg mt-1" v-if="exam_type=='practical_exam'"> <strong>Exam day</strong>: {{item.exam_day_name}}</div>
                                    <div class="font-size-lg mt-1" v-if="exam_type=='practical_exam'"> <strong>Grade</strong>: {{item.grade_name}}</div>
                                    
                                </td>
                                <td style="max-width: 100px !important; white-space: pre-wrap;">
                                    <span class="font-size-lg">{{item.notification_preference ? item.notification_preference : 'N/A'}}</span>
                                </td>
                                <td v-if="(exam_type == 'practical_exam' || exam_type == 'online_exam' || exam_type == 'ameb_award')  && print_type!=''">
                                    <div class="font-size-lg">
                                        <div class="my-2">
                                            Certificate print date:
                                            <span class="badge"
                                                    v-bind:class="{ 'badge-success': item.is_certificate_printed, 'badge-danger': !item.is_certificate_printed }"
                                            >{{ item.is_certificate_printed ? item.certificate_printed_date ? item.certificate_printed_date : 'Yes' : 'NA' }}</span>
                                        </div>

                                        <div class="my-2">
                                            Certificate sent:
                                            <span class="ml-2 badge text-lg"
                                                    v-bind:class="{ 'badge-success': item.is_dispatched, 
                                                    'badge-danger': !item.is_dispatched }"
                                            >{{ item.dispatched_date ? item.dispatched_date : 'No' }}</span>
                                        </div>

                                        <div class="my-2">
                                            Marks obtained:
                                            <span class="badge badge-primary">
                                                {{item.mark_obtained}}
                                            </span>
                                        </div>
                                      <div class="my-2">
                                       Result available date:
                                        <span class="badge badge-primary">
                                                {{item.result_available_date}}
                                            </span>
                                      </div>

                                        <div class="my-2">
                                            Result notified:
                                            <span class="badge"
                                                    v-bind:class="{ 'badge-success': item.result_notified, 'badge-danger': !item.result_notified }"
                                            >{{ item.result_notified ? 'Yes' : 'No' }}</span>
                                        </div>
                                      <div class="my-2" v-if="exam_type != 'ameb_award'">
                                        Report type:
                                        <span class="badge badge-info"
                                        >{{ item.report_type ? item.report_type : 'Digital'}}</span>
                                      </div>
                                      <div class="my-2">
                                        Notification method:
                                        <span class="badge badge-info"
                                        >{{ item.notification_method}}</span>
                                      </div>
                                      <div class="my-2" v-if="item.school_pay_status">
                                        <span class='text-danger'><b>SchoolPay pending payment</b> </span>
                                      </div>
                                    </div>
                                </td>
                                
                                
                            </tr>
                            <tr v-if="pendingData.length == 0">
                                <td colspan="8" class="text-center">
                                    No items found
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div
                    v-if="pendingData.length == 0"
                    class="text-center"
                >
                    No items found
                </div>
                <div>
                    <b-pagination
                        v-if="pendingData.length > 0"
                        class="pull-right mt-7"
                        @input="getPendingData"
                        v-model="page"
                        :total-rows="total"
                        :disabled="loading"
                        :per-page="perPage"
                        first-number
                        last-number
                    ></b-pagination>
                </div>
            </div>
            
          </div>
        </div>
      </div>
      
      <!-- corequisite product dialog box -->
      <v-dialog
            v-model="dialog"
            max-width="800px"
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
        >
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                    <span>Additional requirements</span>
                    <hr>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="px-3">Name</th>
                                        <th class="px-3">Subject code</th>
                                        <th class="px-3">Grade</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in corequisiteproducts">
                                        <td class="px-2">
                                            {{item.name}}
                                        </td>
                                        <td class="px-2">
                                            {{item.subject_code}}
                                        </td>
                                        <td class="px-2">
                                            {{item.grade ? item.grade.name : ''}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- get notification title dialog from user -->
        <v-dialog
            v-model="notificationTitleDialog"
            max-width="800px"
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
        >
            <v-card>
                <v-toolbar dark>
                    <v-card-title>
                    <span>Title</span>
                    <hr>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeNotificationTitleDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="userNotificationTitle"
                                    label="Enter title to save or leave empty for auto generated title"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        dark
                        x-large
                        @click="closeNotificationTitleDialog"
                    >
                    Continue
                    </v-btn>


                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
  </v-app>
</template>

<script>
import LocationService from "@/services/practical-exam/location/LocationService";
import GradeService from "@/services/product/grade/GradeService";
import ScoreService from "@/services/score/score/ScoreService";
import PendingPrintService from "@/services/pending-print/PendingPrintService";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import AmebAwardExamService from "@/services/candidate/ameb-award/AmebAwardExamService";
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import ExamDayService from "@/services/practical-exam/exam-day/ExamDayService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import ProductService from "@/services/product/ProductService";
import {required} from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";

const pendingPrint = new PendingPrintService();
const location = new LocationService();
const grade = new GradeService();
const score = new ScoreService();
const onlineExamResult = new CandidateOnlineExamResultService();
const practicalExamResult = new CandidatePracticalExamResultService();
const candidate = new CandidateService();
const examSession = new ExamSession();
const examDay=new ExamDayService();
const admin_user = new AdminUserService();
const product = new ProductService();
const enrolmentYear=new EnrolmentYearService();
const amebAwardExam = new AmebAwardExamService();

export default{
    validations:{
        candidate_ids:{required}
    },
    data(){
        return {    
            total: 0,
            perPage: null,
            page: null, 
            loading: false, 
            print_type: 'certificate',
            showExamType: false,
            exam_type: 'practical_exam',
            totalPendingCount: 0,
            totalSelectCount: 0,
            isSelectExamTypeLoading: false,
            isMarkAsCompleteLoading: false,
            isMarkAsDispatchedLoading: false,
            enrolment_years:[],
            currentYear:new Date().getFullYear(),
            year:{},
            notification_types:[
                {
                    name: 'Postal',
                    value: 'postal'
                },
                {
                    name: 'Email',
                    value: 'email'
                },
            ],
            exam_types:[
                {name: 'Practical exam', value:'practical_exam'},
            ],
            print_types:[
                {name: 'Report', value: 'report'},
                {name: 'Certificate', value: 'certificate'},
                {name: 'Label', value: 'label'},
            ],
          printing_statuses:[
            {name: 'Pending', value:0},
            {name: 'Printed', value:1},
          ],
            search: {
                exam_key: '',
                first_name: '',
                last_name: '',
                location_id: '',
                grade_id: '',
                start_date: '',
                end_date: '',
                notification_type: '',
                printing_status: 0,
                score_id: '',
                exam_session_id: '',
                exam_day_id: '',
            },
            scores:[],
            pendingData: [],
            candidate_ids:[],
            isSelectedDataLoading: false,
            isAllDataLoading: false,
            currentUser:{},
            locations: [],
            grades:[],
            checkAllCandidateFlag: false,
            menuStartDate: false,
            menuEndDate: false,
            examSessions: [],
            examDays: [],
            isLocationLoading: false,
            isExamSessionLoading: false,
            isExamDayLoading: false,
            score_purchase_prefix: '',
            appUrl: '',
            loadingExportCsv: false,
            save_as_private_print: true,
            currentUserEncryptedId: '',
            corequisiteproducts: [],
            dialog: false,
            notificationTitleDialog: false,
            userNotificationTitle: '',
            selectedType: ''
        }
    },
    methods:{
      getAllEnrolmentYears() {
        enrolmentYear
            .all()
            .then(response =>{
              this.enrolment_years=response.data;
              // this.year = this.enrolment_years.filter(item => item.year == this.currentYear);
              if(this.currentUser.access_type=='score') {
                // this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
                this.getCandidates();
              }else{
                if(this.year.length>0){
                  this.search.enrolment_year_id=this.year[0].id;
                  this.getCandidates();
                }else{
                  this.getCandidates();
                }
              }
            })
      },
        searchPendingData(){
            this.total = null;
            this.perPage = null;
            this.page = null;
            if(! this.checkIfScoreHasBeenSelected()){
                this.$snotify.info('Please select SCORE');
                return false;
            }
            this.getPendingData();
        },
        getPendingData(){
            if(this.exam_type == 'practical_exam' && this.print_type == 'certificate'){
                this.getPracticalPendingPplateCertificates();
            }
        },
        closeNotificationTitleDialog(){
            this.notificationTitleDialog = false;
            this.prepareData(this.selectedType);
        },
        selectPrintType(){
            // this.resetForm();
            this.pendingData = [];
            this.exam_type = '';
            this.search = {
                enrolment_id: '',
                exam_key: '',
                first_name: '',
                last_name: '',
                location_id: '',
                grade_id: '',
                start_date: '',
                end_date: '',
                notification_type: '',
                printing_status: 0,
                score_id: '',
                exam_session_id: '',
                exam_day_id: '',
            };
            this.isSelectExamTypeLoading = true;
            
            this.exam_types = [];
            if(this.print_type == 'report'){
                this.exam_types.push(
                    {name: 'Practical Exam', value:'practical_exam'},
                );
            }
            if(this.print_type == 'certificate' || this.print_type == 'label'){
                this.exam_types.push(
                    {name: 'Practical Exam', value:'practical_exam'},
                    {name: 'Online Exam', value:'online_exam'},
                    {name: 'AMEB Award', value:'ameb_award'}
                );
            }
            
            this.isSelectExamTypeLoading = false;
            this.showExamType = true;
        },
        checkAllCandidate(){
            if(this.checkAllCandidateFlag){
                this.pendingData.forEach(item => {
                    this.candidate_ids.push(item.id);
                });
            }
            else{
                this.candidate_ids = [];
            }
        },
        // practical exam reports
        getPendingReports(){
            this.loading = true;
            practicalExamResult
            .getPendingReports(this.search, this.page)
            .then((response) => {
                this.pendingData = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;

                this.loading = false;

            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {

            });
        },
        resetForm(){
            this.totalPendingCount = 0;
            this.print_type = '';
            this.showExamType = false;
            this.exam_type = '';
            this.isSelectExamTypeLoading = false;
            
            this.search = {
                exam_key: '',
                first_name: '',
                last_name: '',
                location_id: '',
                grade_id: '',
                start_date: '',
                end_date: '',
                notification_type: '',
                score_id: '',
                exam_session_id: '',
                exam_day_id: '',
                printing_status: 0,
            };
            this.pendingData = [];
            
            this.candidate_ids = [],
            this.isSelectedDataLoading = false;
            this.isAllDataLoading = false;
            this.locations = [];
            this.grades = [];
            this.checkAllCandidateFlag = false;
        },
        markPrintedDispatched(action){

            this.$confirm({
            message: `Are you sure? `,
            button: {
                no: "No",
                yes: "Yes",
            },
            callback: (confirm) => {
                if (confirm) {
                let data = {
                    action: action,
                    candidate_ids: this.candidate_ids,

                }
                candidate
                    .markCertificatePrintedDispatched(data)
                    .then((response) => {
                        this.$snotify.success(`Report updated`);
                        this.searchPendingData();
                    })
                    .catch((err) => {
                        this.$snotify.error(`Something went wrong`);
                    })
                }
            }
            })
        },
        
        getPracticalPendingPplateCertificates(){
            this.loading = true;
            practicalExamResult
            .getPracticalPendingPplateCertificates(this.search, this.page)
            .then((response) => {
                this.pendingData = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {

            });
        },
        openNotificationTitleDialog(type){
            this.notificationTitleDialog = true;
            this.selectedType = type;
        },
        prepareData(type){
            let data = {
                candidate_ids: this.candidate_ids,
                exam_key: this.search.exam_key,
                first_name: this.search.first_name,
                last_name: this.search.last_name,
                location_id: this.search.location_id,
                grade_id: this.search.grade_id,
                start_date: this.search.start_date,
                end_date: this.search.end_date,
                notification_type: this.search.notification_type,
                score_id: this.search.score_id,
                exam_session_id: this.search.exam_session_id,
                exam_day_id: this.search.exam_day_id,
                save_as_private_print: 1,
                user_notification_title: this.userNotificationTitle,
                enrolment_year_id: this.search.enrolment_year_id,
                printing_status: this.search.printing_status
            };

            if(type == 'selected'){
                this.$v.$touch()
                if (this.$v.$error) {
                    this.$snotify.error('Please select an option');
                } else {
                    this.isSelectedDataLoading = true;

                    if(this.exam_type == 'practical_exam' && this.print_type == 'certificate'){
                        this.sendPracticalPplateCertificateData(type, data);
                    }

                }
            }

            if(type == 'all'){
                this.isAllDataLoading = true;

                if(this.exam_type == 'practical_exam' && this.print_type == 'certificate'){
                    this.sendPracticalPplateCertificateData(type, data);
                }

            }
            this.save_as_private_print = 1;
            this.userNotificationTitle = '';
        },

        sendPracticalPplateCertificateData(type, data){
            pendingPrint
            .preparePracticalPplateCertificates(type, data)
            .then((response) => {
                this.$snotify.success(response.data.message);
                this.isSelectedDataLoading = false;
                this.isAllDataLoading = false;
                this.candidate_ids = [];
                this.checkAllCandidateFlag = false;
                this.getPracticalPendingPplateCertificates();
            })
            .catch((err) => {
                this.$snotify.error('Oops looks like something went wrong.');
                this.isSelectedDataLoading = false;
                this.isAllDataLoading = false;
            })
            .finally(() => {

            });
        },
        
        getLocations(score_id){
            this.isLocationLoading = true;
            location
            .getByScore(score_id)
            .then((response) => {
                this.locations = response.data;
                this.isLocationLoading = false;
            })
            .catch((err) => {
                this.isLocationLoading = false;
            })
            .finally(() => {

            });
        },
        getExamSessions(score_id){
            this.isExamSessionLoading = true;
            let data = { 
                location_id: this.search.location_id,
                search_by_enrolment_year: true
            };

            examSession
            .getByScore(score_id, data)
            .then((response) => {
                this.examSessions = response.data;
                this.search.exam_day_id = '';
                this.examDays = [];
                this.isExamSessionLoading = false;
            })
            .catch((err) => {
                this.isExamSessionLoading = false;
            })
            .finally(() => {

            });
        },
        getExamDays(examSessionId){
            this.isExamDayLoading = true;

            examDay
            .getByExamSession(examSessionId)
            .then((res) => {
                this.examDays = res.data;
                this.isExamDayLoading = false;
            })
            .catch((err) => {
                this.isExamDayLoading = false;
            })
            .finally(() => {

            })
        },
        getGrades(){
            grade
            .all()
            .then((response) => {
                this.grades = response.data.grades;
            })
            .catch((err) => {

            })
            .finally(() => {

            });
        },
        getScores(){
            score
            .all()
            .then(res => {
                this.scores = res.data.scores;
            })
            .catch((err) => {

            });
        },
        getCurrentUser() {
            this.currentUser =  this.$store.getters.currentUser;
            if(this.currentUser.access_type == 'score'){
                this.getLocations(this.currentUser.score_id);
                this.getGrades();
                this.score_purchase_prefix = this.currentUser.score.purchase_prefix;    
            }
            if(this.currentUser.access_type == 'federal'){
                this.getScores();
            }
        },
        handleScoreChange(){
            if(this.search.score_id == ''){
                this.search.location_id = '';
                this.search.exam_day_id = '';
                this.search.exam_session_id = '';
                this.locations = [];
                this.examDays = [];
                this.examSessions = [];
            }
            
            if(this.exam_type == 'practical_exam'){
                if(this.search.score_id != ''){
                    this.getLocations(this.search.score_id);
                    this.getExamSessions(this.search.score_id);
                }
            }

            // setting score prefix
            if(this.search.score_id != ''){
                let selectedScore = this.scores.filter((item) => {
                    return (item.id == this.search.score_id);
                });
                
                if(selectedScore.length > 0){
                    this.score_purchase_prefix = selectedScore[0].purchase_prefix;
                }
            }
        },
        handleLocationChange(){            
            if(this.search.location_id == ''){
                this.search.exam_session_id = '';
                this.search.exam_day_id = '';
                this.examDays = [];
                this.examSessions = [];
            }
            if(this.exam_type == 'practical_exam'){
                if(this.search.location_id != ''){
                    this.getExamSessions(this.search.score_id ? this.search.score_id : this.currentUser.score_id);
                }
            }
        },
        handleExamSessionChange(){
            if(this.search.exam_session_id == ''){
                this.search.exam_day_id = '';
                this.examDays = [];
            }
            if(this.exam_type == 'practical_exam'){
                if(this.search.exam_session_id != ''){
                    this.getExamDays(this.search.exam_session_id);
                }
            }
        },
        checkIfScoreHasBeenSelected(){
            if(this.currentUser.access_type == 'federal'){
                if(this.search.score_id =='' || this.search.score_id == null){
                    return false;
                }
                return true;
            }else{
                return true;
            }
        },
        selectExamType(){
            this.page = 1;
            
            this.search = {
                enrolment_year_id: this.search.enrolment_year_id,
                exam_key: '',
                first_name: '',
                last_name: '',
                location_id: '',
                grade_id: '',
                start_date: '',
                end_date: '',
                notification_type: '',
                score_id: '',
                exam_session_id: '',
                exam_day_id: '',
                printing_status: 0
            };

            this.pendingData = [];

            if(! this.checkIfScoreHasBeenSelected()){
                this.$snotify.info('Please select score');
                return false;
            }

            if(this.exam_type == 'practical_exam' && this.print_type == 'certificate'){
                this.exam_type = 'practical_exam';
                this.getCurrentUser();
                this.getPracticalPendingPplateCertificates();
            }
            else{
                this.resetForm();
            }
        },
        getCandidateSummary(examKey){
            this.$router.push({
                name: 'candidate-summary',
                params: {examKey: examKey}
            })
        },
        exportCsv(){            
            if(this.candidate_ids.length == 0){
                this.$snotify.error('Please select a candidate.');
                return false;
            }

            if(this.candidate_ids.length > 0){
                this.exportCsvPrintJobs(this.candidate_ids);
                this.candidate_ids = [];
            }
        },
        exportCsvPrintJobs(data){
            pendingPrint
            .exportCsvPrintJobs(data)
            .then((response) => {
                this.$snotify.success(response.data.message);
                this.isSelectedDataLoading = false;
                this.isAllDataLoading = false;
                this.candidate_ids = [];
                this.checkAllCandidateFlag = false;
                this.getPendingReports();
            })
            .catch((err) => {
                this.$snotify.error('Oops looks like something went wrong.');
                this.isSelectedDataLoading = false;
                this.isAllDataLoading = false;
            })
            .finally(() => {

            });
        },
        getCurrentUserEncryptedId(){
            admin_user
            .getCurrentUserEncryptedId()
            .then((res)=>{
                this.currentUserEncryptedId = res.data.encryptedId;
            })
            .catch((err) => {

            })
        },
        getCoRequisiteProduct(productId)
        {
            product
            .getCoRequisiteProduct(productId)
            .then((res) => {
                this.corequisiteproducts = [];
                this.corequisiteproducts = res.data.corequisiteproducts;
                if(this.corequisiteproducts.length > 0){
                    this.dialog = true;
                }
            })
            .catch((err) => {

            })
        },
        closeDialog(){
            this.dialog = false;
        },
        viewLoadingPrint() {
            this.$router.push({
                name: "loading-print",
            });
        },
    },
    mounted(){
        this.getCurrentUser();
        this.getAllEnrolmentYears();
        this.appUrl = process.env.VUE_APP_URL;
        this.getCurrentUserEncryptedId();
    }
}
</script>
